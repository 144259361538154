<template>
  <div class="container bdgerview">
    <router-view />
  </div>
</template>

<script>
export default {
  components: {},
}
</script>

<style scoped lang="scss">
.bdgerview {
  @include flex(row);
  align-items: center;
  justify-content: center;
  position: relative;
}
</style>
